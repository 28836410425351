<template>
    <div class="questions__list">
        <div class="questions__item question"
             :class="{
                 'question_open': open === 1
             }">
            <div class="top">
                <div
                    @click="toggle(1)"
                    class="question__title"
                >
                    Условия выдачи займа
                    <span></span>
                </div>
            </div>
            <VueSlideUpDown :active="open === 1">
                <div class="question__content-wrapper">
                    <div class="question__content">
                        <h2>Чтобы получить деньги, нужно совершить несколько простых действий</h2>
                        <ol>
                            <li>
                                Зарегистрируйтесь на сайте проекта: для этого нужно нажать кнопку «Получить деньги».
                            </li>
                            <li>
                                Заполните анкету с необходимыми данными (понадобится паспорт).
                            </li>
                            <li>
                                Введите данные банковской карты и активируйте сервис.
                            </li>
                            <li>
                                Выберите самое интересное предложение и оформите займ. После короткого рассмотрения заявки на карту поступит зачисление.
                            </li>
                        </ol>
                    </div>
                </div>
            </VueSlideUpDown>
        </div>
        <div class="questions__item question"
             :class="{
                 'question_open': open === 2
             }">
            <div class="top">
                <div
                    @click="toggle(2)"
                    class="question__title"
                >
                    Как долго рассматривается заявка?
                    <span></span>
                </div>
            </div>
            <VueSlideUpDown :active="open === 2">
                <div class="question__content-wrapper">
                    <div class="question__content">
                        <ol>
                            <li>
                                Часто обстоятельства складываются так, что деньги нужны здесь и сейчас. Мы понимаем это и относимся к вашему времени, как к своему: чтобы вы могли получить займ в любой момент, работаем 24/7.
                            </li>
                            <li>
                                Чтобы заполнить анкету, вам потребуется около 3 минут, рассмотрение заявки займет еще 5 минут, — и по истечении этого срока вы получите деньги удобным способом.
                            </li>
                        </ol>
                    </div>
                </div>
            </VueSlideUpDown>
        </div>
        <div class="questions__item question"
             :class="{
                 'question_open': open === 3
             }">
            <div class="top">
                <div
                    @click="toggle(3)"
                    class="question__title"
                >
                    Если я не успеваю вернуть заем?
                    <span></span>
                </div>
            </div>
            <VueSlideUpDown :active="open === 3">
                <div class="question__content-wrapper">
                    <div class="question__content">
                        <ol>
                            <li>
                                Согласуйте рефинансирование вашего займа. Вам предложат погасить сумму на более лояльных условиях, которые помогут избежать больших штрафов за просрочку платежа.
                            </li>
                        </ol>
                    </div>
                </div>
            </VueSlideUpDown>
        </div>
        <div class="questions__item question"
             :class="{
                 'question_open': open === 4
             }">
            <div class="top">
                <div
                    @click="toggle(4)"
                    class="question__title"
                >
                    Получу ли я деньги с плохой кредитной историей?
                    <span></span>
                </div>
            </div>
            <VueSlideUpDown :active="open === 4">
                <div class="question__content-wrapper">
                    <div class="question__content">
                        <ol>
                            <li>
                                Мы понимаем, что ситуации могут быть разными, и работаем со всеми клиентами: подберем для вас организации, которые готовы работать с заемщиками даже при наличии плохой кредитной истории.
                            </li>
                            <li>
                                Факт: если вы кредитуетесь в организации, которая входит в реестр Центрального банка, вовремя выплаченные займы помогают улучшить вашу кредитную историю.
                            </li>
                        </ol>
                    </div>
                </div>
            </VueSlideUpDown>
        </div>
        <div class="questions__item question"
             :class="{
                 'question_open': open === 5
             }">
            <div class="top">
                <div
                    @click="toggle(5)"
                    class="question__title"
                >
                    Сколько я могу получить и на какой срок?
                    <span></span>
                </div>
            </div>
            <VueSlideUpDown :active="open === 5">
                <div class="question__content-wrapper">
                    <div class="question__content">
                        <ol>
                            <li>
                                У пользователя сервиса есть возможность получить займ до 100 000 рублей на срок до 1 года
                            </li>
                        </ol>
                    </div>
                </div>
            </VueSlideUpDown>
        </div>
        <div
            class="questions__item question"
            :class="{
                'question_open': open === 6
            }"
        >
            <div class="top">
                <div
                    @click="toggle(6)"
                    class="question__title"
                >
                    Переплаты и комиссии по займу?
                    <span></span>
                </div>
            </div>
            <VueSlideUpDown :active="open === 6">
                <div class="question__content-wrapper">
                    <div class="question__content">
                        <ol>
                            <li>
                                Комиссия за выдачу займа составляет 0%. Обязательное страхование жизни и здоровья заемщика отсутствует. Переплата по займу составляет от 0 рублей и зависит от условий индивидуального кредитного предложения подобранного для клиента.
                            </li>
                        </ol>
                    </div>
                </div>
            </VueSlideUpDown>
        </div>
    </div>
</template>

<script>
import './questions.scss'
import VueSlideUpDown from 'vue-slide-up-down'

export default {
    name: 'Questions',
    data() {
        return {
            open: null
        }
    },
    methods: {
        toggle(num) {
            if (this.open === num) {
                this.open = null
            } else {
                this.open = num
            }
        }
    },
    components: {
        VueSlideUpDown
    }
}
</script>